@font-face {
  font-family: 'OpenSans';
     src: url('./fonts/OpenSans-Bold.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
     src: url('./fonts/OpenSans-ExtraBold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
     src: url('./fonts/OpenSans-Regular.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
     src: url('./fonts/OpenSans-Light.ttf');
  font-weight: 100;
  font-style: normal;
}

body {
  background-color: #282c34;
}

.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: #e6e6e6; */
  /* background-image: radial-gradient(circle, #efefef, #e6e6e6, #d8d8d8); */
  background-image: radial-gradient(circle, #464953, #373942, #282c34);
  font-family: 'OpenSans';
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  color: white;
}
/* 
.App h1, h2 {
  font-weight: 100;
  color: '#ffffff80';
  margin: 0;
}

.App h1 {
  font-size: 3rem;
}

.App h2 {
  font-size: 1rem;
}

.App header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App section {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.btn a {
  padding: 2rem;
  background-color: #ffffff20;
  margin-left: 1px;
}

.btn a {
  opacity: 0.6;
  transition: 0.3s;
}

.btn a:hover {
  opacity: 1;
  cursor: pointer;
}

.btn .btn-options {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  display: none;
}

.btn:hover > .btn-options {
  visibility: visible;
  opacity: 1;
  display: block;
}

.btn-options {
  display: block;
  transition-duration: 0.5s;
} */

.full-img {
  width: 70vw;
}

.mobile-portrait .full-img {
  width: 100vw;
}

.small-img {
  height: 200px;
}

.App {
  display: flex;
  flex-direction: row;
}

.App.Is-Home {
  justify-content: center;
}

.Mobile-Back {
  position: absolute;
  top: 0px;
}

.ReinforcementLearning {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ReinforcementLearning #app {
  background: none;
}

.Simulation {
  flex: 1;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

p {
  font-weight: 100;
  padding: 0rem 1rem;
}

p a {
  color: #ffffff;
  font-weight: 300;
}

.mobile-portrait .mobile-landscape p a {
  text-decoration: underline;
}

p a:hover {
  text-decoration: underline;
}

.CTA-Button {
  padding: 1rem;
  border: 1px solid #ffffff80;
  text-align: center;
  margin: 0 1rem;
  background: #ffffff00;
  transition: 500ms;
}

.CTA-Button:hover {
  background: #ffffff80;
}

h1 {
  margin: 0;
  padding: 0;
  color: white;
  padding-top: 1rem;
  font-weight: 100;
  font-size: 1rem;
  display: inline;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

h2 {
  color: white;
  font-weight: 100;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

h2 span {
  margin: 0;
  padding: 0;
  flex: 1;
  color: white;
  /* padding-left: 1rem; */
  font-weight: 100;
  font-size: 2rem;
  display: inline;
  transition: 500ms;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.mobile-portrait h2:hover {
  padding-left: 2rem;
  font-weight: 200;
}

h2:hover {
  font-weight: 200;
}