.Menu {
  display: flex;
  flex-direction: column;
}

.Case {
  /* width: 20vw; */
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
}

.desktop.Menu {
  /* align-items: center; */
  align-self: flex-start;
}

.mobile-portrait .Case, .mobile-portrait .About {
  width: 100vw;
}

.desktop .Case, .desktop .About, .mobile-landscape .Case, .mobile-landscape .About {
  width: 30vw;
}

.Case div {
  padding: 2rem;
  color: white;
  font-weight: 300;
  background-color: #00000040;
  transition: 500ms;
}

.mobile-portrait .Case div {
  padding: 3rem;
}

.Case div:hover {
  background-color: #ffffff20;
  /* font-weight: 400; */
}

.AI {
  background-image: url(media/bgm.png);
}

.Case.NLP {
  background-image: url(media/text.png);
}

.Simulations {
  background-image: url(media/sim.png);
}

.Contact a:last-child {
  margin-right: 0;
}

.Contact a {
  margin-left: 1rem;
}

.Contact a svg {
  width: 24px;
  height: 24px;
}

.Contact a svg path {
  fill: #ffffffa0;
}

.Contact a:hover svg path {
  fill: #fffffff0;
}

.About {
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.About p {
  padding: 0rem 1rem;
  color: white;
  font-weight: 200;
  font-size: 0.75rem;
  text-align: justify;
}

